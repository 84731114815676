// store/modules/userLoyaltyHistoryPointsList.js
const state = {
    filters: {
      date: [],
      pointsGained: [],
      totalPoints: [],
      pagination: {
        limit: 10,
        page: 1,
        pages: 1,
      },
      sortFilter: {
        options: [
          {
            text: 'Último creado',
            value: 'created_at'
          },
        ],
        optionValue: 'created_at',
        sortModes: [
          {
            text: 'Ascendente',
            value: 1
          },
          {
            text: 'Descendente',
            value: -1
          }
        ],
        sortModeValue: -1,
      },
      searchFilter: {
        searchValue: '',
        options: [
          {
            text: 'Usuario',
            value: 'unique_id'
          },
        ],
        optionValue: '',
      },
      levelAutoComplete: {
        value: [],
        options: [],
        selectAll: false
      },
      fleetAutoComplete: {
        value: [],
        options: [],
        selectAll: false
      },
      typeFilter: {
        options: [
          {
            text: 'Viaje creado',
            value: 1
          },
          {
            text: 'Viaje cancelado',
            value: 2
          },
          {
            text: 'Categorización',
            value: 3
          }
        ],
        optionValue: ''
      },
      paymentMethodAutoComplete: {
        value: [],
        options: [],
        selectAll: false
      }
    },
    // Other states...
  };
  
  const mutations = {
    SET_FILTERS(state, filters) {
      state.filters = filters;
    },
    // Other mutations...
  };
  
  const actions = {
    setFilters({ commit }, filters) {
      commit('SET_FILTERS', filters);
    },
    // Other actions...
  };
  
  const getters = {
    filters: (state) => state.filters,
    // Other getters...
  };
  
  export default {
    namespaced: true, // Important for namespacing in Vuex
    state,
    mutations,
    actions,
    getters,
  };