let baseState = {
    filters: {
      sortFilter: {
        options: [
          {
            text: 'ID',
            value: 'id'
          }
        ],
        optionValue: 'created_at',
        sortModes: [
          {
            text: 'Ascendente',
            value: 1
          },
          {
            text: 'Descendente',
            value: -1
          }
        ],
        sortModeValue: -1
      },
      searchFilter: {
        searchValue: '',
        options: [
          {
            text: 'ID',
            value: 'id'
          },
          {
            text: 'Nombre de la categoría',
            value: 'name'
          }
        ],
        optionValue: 'name'
      },
      statusFilter: {
        options: [],
        optionValue: ''
      },
      categoryFilter: {
        options: [],
        optionValue: '',
        loading: false
      },
      pagination: {
        page: 1,
        pages: 1,
        limit: 10
      },
      expandTable: false
    }
  };
  
  const state = baseState;
  
  const mutations = {
    SET_FILTERS(state, filters) {
      state.filters = { ...state.filters, ...filters };
    }
  };
  
  const actions = {
    setFilters({ commit }, filters) {
      commit('SET_FILTERS', filters);
    },
    resetFilters({ commit }) {
      commit('SET_FILTERS', baseState);
    }
  };
  
  const getters = {
    user: (state) => state.filters
  };
  
  export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
  };
  